import { ImagePlusTextProps } from '@design-system/image-plus-text';
import { ctaBlockMapper } from 'mappers/ctaBlock';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image/imageMapper';

import { ImagePlusTextBlock } from './models';

export const imagePlusTextBlockMapper = ({
  image,
  content,
  heading: [heading],
  cta_blocks: { cta_blocks: ctaBlocks },
  image_position: imagePosition,
  align,
}: ImagePlusTextBlock): ImagePlusTextProps =>
  ({
    image: imageMapper(image),
    ...(content ? { content } : undefined),
    ...(heading ? { headingBlock: headingBlockMapper(heading) } : undefined),
    ...(ctaBlocks.length && { ctaBlock: ctaBlockMapper(ctaBlocks) }),
    imagePosition,
    align,
  } as ImagePlusTextProps);
