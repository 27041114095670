import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout/Layout';
import BodyRender from 'components/BodyRender';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import type { TCommonPageData } from './models';

import './CommonPage.scss';

/**
 * Private template
 */
export const CommonPage: FC<TCommonPageData> = ({
  data: {
    pageData,
    footer,
    header,
    headerAccount,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
    redirects,
  },
  pageContext: { uid },
  pageContext,
  location,
}) => {
  getBuildProcessInfo(pageContext);
  const { seo, body, page_type, locale } = pageData;

  return (
    <Layout
      seo={seo}
      pageUid={uid}
      className={`page-type-${page_type}`}
      color="blue"
      footer={footer}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      header={header}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      headerMode="transparent"
      headerAccount={headerAccount}
      redirects={redirects}
      location={location.pathname}
      privatePage
      data-testid="CommonPage"
    >
      <BodyRender items={body} locale={locale} currentPageUid={uid} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishCommonPage(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
      fields {
        ...PageFieldsFragment
      }
      page_type
      body {
        ...BodyFragment
      }
      locale
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  CommonPage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_common_page`
);
