import React, { FC } from 'react';
import loadable from '@loadable/component';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageBlockMapper } from 'mappers/imageBlock';
import { imagePlusTextBlockMapper } from 'mappers/imagePlusTextBlock';
import { typographyMapper } from 'mappers/typography';

import { BodyRenderProps } from './models';

const Typography = loadable(() => import('@design-system/typography'), {
  resolveComponent: (components) => components.Typography,
});
const ImageBlock = loadable(() => import('components/ImageBlock'));
const ImagePlusTextBlock = loadable(() => import('@design-system/image-plus-text'), {
  resolveComponent: (components) => components.ImagePlusText,
});
const HeadingBlock = loadable(() => import('@design-system/heading-block'), {
  resolveComponent: (components) => components.HeadingBlock,
});
const DebugComponent = loadable(() => import('./DebugComponent'));
const ListTreeWrapper = loadable(() => import('components/ListTree/ListTreeWrapper'));

const typesMapper = {
  default: (typeName) => [
    (item) =>
      process.env.NODE_ENV === 'development' ? (
        <DebugComponent typeName={typeName} {...item} />
      ) : null,
    (item) => item,
  ],
  Typography: [Typography, typographyMapper],
  HeadingBlock: [HeadingBlock, headingBlockMapper],
  SitemapBlock: [ListTreeWrapper],

  // Not implemented in the body
  ImageBlock: [ImageBlock, imageBlockMapper],
  ImagePlusTextBlock: [ImagePlusTextBlock, imagePlusTextBlockMapper],
};

const BodyRender: FC<BodyRenderProps> = ({ items, currentPageUid, externalData, locale }) => {
  const renderItems = items.map((item) => {
    const value = Object.values(item).find((val) => val);

    return value || { typeName: 'Unknown component', item: [] };
  });

  return (
    <>
      {renderItems.map(({ typeName, ...item }, index) => {
        const [ItemComponent, itemMapper] = typesMapper[typeName] || typesMapper.default(typeName);

        const key = `${typeName}__${index}`;
        const value = Array.isArray(item) ? item[0] : item;

        return (
          <ItemComponent
            key={key}
            locale={locale}
            {...(itemMapper ? itemMapper(value, currentPageUid, externalData) : value)}
          />
        );
      })}
    </>
  );
};

export default BodyRender;
